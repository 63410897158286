var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
let LoadingElement = class LoadingElement extends LitElement {
    render() {
        return html ``;
    }
};
LoadingElement.styles = css `
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    :host {
      display: block;
      width: 80px;
      height: 80px;
      margin: 40px auto;
      border: solid 6px rgba(56, 24, 185, 1);
      border-radius: 50%;
      border-right-color: rgba(56, 24, 185, 0.25);
      border-bottom-color: rgba(56, 24, 185, 0.25);
      border-left-color: rgba(56, 24, 185, 0.25);
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate;
      -webkit-animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;

      transition: all 0.5s ease-in;
      animation-name: rotate;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  `;
LoadingElement = __decorate([
    customElement('ic-loading')
], LoadingElement);
export { LoadingElement };
