import { html, render } from 'lit';
export class BaseView {
    constructor() {
        this.useRootView = true;
    }
    addRoot(slot) {
        return html `<ic-root>${slot}</ic-root>`;
    }
    async content() {
        return html ``;
    }
    async beforeRender() {
        return true;
    }
    async afterRender() {
        return;
    }
    async render() {
        const readyToRender = await this.beforeRender();
        if (!readyToRender) {
            return;
        }
        const content = await this.content();
        const element = document.getElementById('root');
        if (element) {
            render(this.addRoot(content), element);
        }
        await this.afterRender();
    }
}
