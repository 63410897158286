export function getValueFromCookie(name, cookie = document.cookie) {
    var _a, _b;
    return (_b = (_a = cookie.match(new RegExp(`(?:${name}=)(.*?)(?:;|$)`))) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : null;
}
export async function hashString(str, algorithm = 'SHA-256') {
    const data = new TextEncoder().encode(str);
    const hashBuffer = await crypto.subtle.digest(algorithm, data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
export function parseSafeInteger(value, radix = 10) {
    if (!value) {
        return NaN;
    }
    const parsedNumber = Number.parseInt(value, radix);
    if (!Number.isNaN(parsedNumber) &&
        parsedNumber >= Number.MIN_SAFE_INTEGER &&
        parsedNumber <= Number.MAX_SAFE_INTEGER) {
        return parsedNumber;
    }
    return NaN;
}
